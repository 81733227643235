<template>
  <div class="form-container">
    <span class="back" @click="$router.go(-1)"> <font-awesome-icon icon="chevron-left" /> Volver </span>
    <loading :active.sync="loading" :is-full-page="true"></loading>
    <div class="header-container">
      <div class="text-center">
        <label class="text-uppercase">Anular Citas</label>
        <p>Ingresa los datos solicitados para anular tu cita</p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-10 col-md-10  ">
        <label><strong>Código de cita</strong></label>
        <input class="form-control" v-model="id" placeholder="Ingrese el código de su cita" />
      </div>
      <div class="col-1 col-md-1">
        <button class="btn btn-success btn-search" @click="search">Buscar</button>
      </div>
    </div>
    <div v-if="errorMessage">
      <p><strong>No se encontró su cita</strong></p>
    </div>
    <div v-if="date">
      <detail-form :date="date" />
      <button @click="showDialog = true" class="btn btn-danger delete">Cancelar Cita</button>
    </div>
    <delete-dialog :dialog-visible="showDialog" @close="showDialog = false" @save="cancelDate" />
    <modal-message :dialog-visible="showMessageModal" @close="closeMessageModal" :message="message" />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DetailForm from "../components/DetailForm.vue";
import Api from "@/api";
import DeleteDialog from "../components/DeleteDialog.vue";
import ModalMessage from "../components/ModalMessage.vue";

const api = new Api();
export default {
  components: { Loading, DetailForm, DeleteDialog, ModalMessage },
  data() {
    return {
      id: "",
      loading: false,
      date: undefined,
      showDialog: false,
      errorMessage: false,
      message: "",
      showMessageModal: false,
    };
  },
  methods: {
    async search() {
      this.date = undefined;
      this.errorMessage = false;
      this.loading = true;
      const response = await api.getAppointment({ id: this.id });
      console.log(response);
      if (response.status == 200) {
        if (response.data.error) {
          this.errorMessage = true;
        } else {
          this.date = response.data.date;
        }
      } else {
        this.errorMessage = true;
      }
      this.loading = false;
    },
    async cancelDate() {
      this.showDialog = false;
      this.loading = true;
      const response = await api.cancelAppointment({ id: this.date.code });
      if ((response.status = 200 && !response.data.error)) {
        this.message = "Su cita ha sido anulada Exitosamente";
        this.loading = false;
        this.showMessageModal = true;
      }
    },
    closeMessageModal() {
      this.showMessageModal = false;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.btn-search {
  margin-top: 54%;
}
.text-uppercase {
  font-weight: 800;
  font-size: 20px;
}
.delete {
  width: 93%;
}
.back {
  margin-top: 20px;
}
</style>
