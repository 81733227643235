<template>
  <div class="modal fade" id="modalInfoMessage" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Atención</h5>
          <button type="button" class="close" @click="close()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="close()" data-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";

export default {
  name: "MessageDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible) {
        $("#modalInfoMessage").modal("show");
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.text-left {
  text-align: left !important;
}
.list-group-flush {
  border-radius: 0;
}
.text-right {
  text-align: right !important;
}
.align-self-center {
  align-self: center !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 425px) {
  .modal-dialog {
    margin-right: 15px;
  }
}
</style>
