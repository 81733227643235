<template>
  <ul class="list-group list-group-flush">
    <h5 class="lead text-left font-weight-bold ">Verifique los detalles de su cita</h5>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Código</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right" id="resume-code">
          {{ date.code }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Fecha</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">
          {{ date.day }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Hora</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">
          {{ date.hour }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Motivo</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">
          {{ date.reason }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Veterinario</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">
          {{ date.veterinary }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Sucursal</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">
          {{ date.office.name }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Dirección</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">{{ date.office.address }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6 pl-0 align-self-center text-left">
          <b>Teléfono</b>
        </div>
        <div class="col-6 pr-0 align-self-center text-right">{{ date.office.phone }}</div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    date: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.list-group {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  padding: 50px;
  padding-top: 30px;
  padding-left: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.95rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.text-left {
  text-align: left !important;
}
.list-group-flush {
  border-radius: 0;
}
.text-right {
  text-align: right !important;
}
.align-self-center {
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

@media (max-width: 425px) {
  .list-group {
    padding: 0;
    margin-top: 23px;
  }
  .lead {
    font-size: 1.1rem;
  }
}
</style>
